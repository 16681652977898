// @flow
import * as React from 'react'
import styled from 'styled-components'

import { color } from '../styles/theme'

//TODO
const Svg = styled.svg`
  path {
    fill: ${({ theme }) =>
      theme.name === 'kids' || theme.name === 'lgbt'
        ? color.white
        : theme.body};
  }

  circle {
    fill: ${({ theme }) =>
      theme.name === 'kids' || theme.name === 'lgbt'
        ? color.yellow
        : color.white};
  }
`

const PlayIcon = ({ style, ...rest }: Object) => (
  <Svg
    viewBox="0 0 40 40"
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', ...style }}
    {...rest}
  >
    <g>
      <circle id="Combined-Shape-Copy" cx="20" cy="20" r="20" />
      <path
        d="M16.28,12.1600917 C16.0376,11.9784928 15.7136,11.9488929 15.4424,12.0840921 C15.1712,12.2200914 15,12.4968898 15,12.800088 L15,27.2000046 C15,27.5032029 15.1712,27.7800013 15.4424,27.9160005 C15.5552,27.9720002 15.6784,28 15.8,28 C15.9696,28 16.1392,27.9456003 16.28,27.8400009 L25.88,20.6400426 C26.0816,20.4896435 26.2,20.2520449 26.2,20.0000463 C26.2,19.7480478 26.0816,19.5112491 25.88,19.36005 L16.28,12.1600917 Z"
        id="Path"
      />
    </g>
  </Svg>
)

export default PlayIcon
