// @flow
import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../styles/theme'
import { breakpoints } from '../../styles/media'

import type { ImageViewModel } from '../../types/ImageViewModel'
import type { VideoViewModel } from '../../types/VideoViewModel'
import type { AreaTypes } from '../../types/enums/AreaTypes'

import Vimeo from '../../components/Vimeo/Vimeo'
import MediaQuery from '../../components/MediaQuery/MediaQuery'
import Image from '../../components/Image/Image'

const Figure = styled.figure`
  position: relative;
  margin: 0;
  overflow: hidden;
  background-color: ${color.bg};
`

export type Props = {
  image?: ImageViewModel,
  video?: VideoViewModel,
  area?: AreaTypes,
}

type State = {
  isPlaying: boolean,
}

class Hero extends React.Component<Props, State> {
  static displayName = 'Hero'
  static defaultProps = {}
  player = null

  state = {
    isPlaying: false,
  }

  // $FlowFixMe
  handleMountVideo = (player: { triggerFullscreen: Function }) => {
    this.player = player
  }

  handlePlayClick = () => {
    if (this.player) {
      this.player.triggerFullscreen()
    }
  }

  handleVideoPlay = () => {
    this.setState({ isPlaying: true })
    const articleWrapperEl = document.getElementById('__article-wrapper__')
    if (articleWrapperEl) {
      // Set Article top position, so it doesn't cover Video when playing
      articleWrapperEl.style.marginTop = '0'
    }
  }

  handleVideoPause = () => {
    this.setState({ isPlaying: false })
    const articleWrapperEl = document.getElementById('__article-wrapper__')
    if (articleWrapperEl) {
      // remove added inline style, so it goes back to default styling
      articleWrapperEl.style.removeProperty('margin-top')
    }
  }

  render() {
    const { video, image } = this.props

    if ((!video || !video.src) && (!image || !image.src)) return null

    return (
      <MediaQuery query={`(min-width: ${breakpoints.sm}em)`}>
        {matches => (
          <Figure aspect={1 / 2}>
            {video && (
              <Vimeo
                ref={this.player}
                {...video}
                controls
                useControlsRibbon
                onPlay={this.handleVideoPlay}
                onPause={this.handleVideoPause}
                onMountVideo={this.handleMountVideo}
                style={{
                  transition: 'padding-bottom .4s ease-out',
                  paddingBottom: matches
                    ? this.state.isPlaying
                      ? '43.25%'
                      : '33.25%'
                    : undefined,
                }}
              />
            )}
            {image && !video && (
              <Image {...image} cover maxAspectRatio={matches ? 0.3325 : 0.6} />
            )}
          </Figure>
        )}
      </MediaQuery>
    )
  }
}

export default Hero
