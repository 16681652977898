// @flow
import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../styles/theme'
import { rem } from 'polished'
import Paragraph from '../../components/Paragraph/Paragraph'
import { getLineHeight, getFontSize } from '../../styles/style-helpers'
import SearchIcon from '../../icons/SearchIcon'
import Image from '../../components/Image/Image'
import Heading from '../../components/Heading/Heading'
import handleOpenSearch from '../Header/ToggleSearch'
import type { HeroFrontpageViewModel } from '../../types/HeroFrontpageViewModel'

const StyledSection = styled.section`
  max-width: 1200px;
  margin: 0;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: ${rem(100)};
  }
  @media (min-width: 1024px) {
    padding-left: ${rem(130)};
  }
  @media (min-width: 1200px) {
    padding-left: ${rem(160)};
  }
  @media (min-width: 1440px) {
    padding-left: 0;
    margin: 0 auto;
  }
`

const Figure = styled.figure`
  position: relative;
  margin: 0;
  overflow: hidden;
  aspect-ratio: 16/10;
  background-color: ${color.bg};
  @media (min-width: 768px) {
    order: 2;
    object-position: right;
    height: 100%;
    aspect-ratio: 8/7;
  }

  @media (min-width: 768px) {
    height: ${rem(700)};
  }
`

const StyledHeading = styled(Heading)`
  @media (min-width: 768px) {
    font-size: ${rem(44)};
  }
`

const TextWrapper = styled.div`
  padding-block: ${rem(40)};
  padding-inline: ${rem(32)};

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: ${rem(72)};
    padding-left: 0;
  }
`

const StyledParagraph = styled(Paragraph).attrs({
  weight: 300,
  size: 'large',
})`
  && {
    color: ${({ theme }) => theme.text};
    line-height: ${getLineHeight('normal')};
    font-size: ${getFontSize('large')};

    @media (min-width: 768px) {
      max-width: 80%;
    }
  }
`

const StyledButton = styled.button`
  background-color: #ffff;
  border: ${rem(2)} solid #00324552;
  padding-block: ${rem(12)};
  padding-inline: ${rem(16)};
  border-radius: ${rem(4)};
  margin-top: ${rem(12)};
  display: flex;
  width: 100%;
  gap: ${rem(8)};
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 0;
    max-width: 80%;
  }
`

const StyledLabel = styled.p`
  color: #003245;
  font-size: ${rem(18)};
  margin-top: ${rem(32)};
`

type Props = HeroFrontpageViewModel

const HeroFrontpage = ({ image, title, introText, searchButton }: Props) => {
  return (
    <StyledSection>
      <Figure>{image && <Image {...image} cover fillContainer />}</Figure>
      <TextWrapper>
        <StyledHeading tag="h1" level={1} responsiveSize>
          {title}
        </StyledHeading>
        <StyledParagraph>{introText}</StyledParagraph>

        <StyledLabel>{searchButton?.label}</StyledLabel>
        <StyledButton
          arial-label={searchButton?.label}
          onClick={() => handleOpenSearch(searchButton?.raffleId)}
        >
          <SearchIcon aria-hidden="true" style={{ marginLeft: 'auto' }} />
        </StyledButton>
      </TextWrapper>
    </StyledSection>
  )
}

HeroFrontpage.defaultProps = {}
HeroFrontpage.displayName = 'HeroFrontpage'

export default HeroFrontpage
